<template>
    <div class="level_index level_indexII">
        <el-dialog :close-on-click-modal="false" title="异常报备" :visible.sync="show" width="500px">
            <!--  -->
            <div class="info">
                <!-- 医院负责人你好：
                <br />
                截止到2023年04月25日，贵院已经连 续72小时未产生医废联单。
                <br />
                请上报贵院未产生医废的原因： -->

                {{info.title}}
                <br />
                {{info.info}}

            </div>


            <div class="radio">

                <!-- <el-radio v-model="radio" label="1">暂时停业</el-radio>
                <el-date-picker v-show="radio == 1" v-model="time" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" placeholder="请选择停业时间" class="SelfSelect">
                </el-date-picker>
                <el-radio v-model="radio" label="2">未产生医废</el-radio>
                <el-radio v-model="radio" label="3">医院关停</el-radio> -->

                <span v-if="info.type==1">暂时停业</span>
                <br>
                <span
                    v-if="info.type==1">时间：{{info.start_time?info.start_time.slice(0,10):""}}~{{info.end_time?info.end_time.slice(0,10):""}}</span>

                <span v-else-if="info.type==2">未产生医废</span>
                <span v-else>医院关停</span>

            </div>

            <!--  -->
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                show: false,

                radio: '',
                time: '',

                info: {}

            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },
            view(row) {

                this.info = {
                    ...row
                }

                this.open()

            },

        }
    }
</script>

<style scoped lang="scss">
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择配置分组"],
  .selfInputBoxIV .el-input__inner[placeholder="请选择配置类型"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */
    /deep/.el-dialog__body {
        padding: 20px 20px 30px 20px;
    }

    .info {
        // border: 1px red solid;
        // box-sizing: content-box;
        width: 90%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        font-size: 14px;
        font-weight: 400;
        color: #626262;
        line-height: 36px;
    }

    .radio {
        width: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .el-radio {
            display: block;
        }

        .SelfSelect {
            width: 100%;
        }
    }
</style>